<template lang="pug">
  .footer-socials.is-flex
    .footer-socials__title {{ $t('layout.footer.follow') }}
    .social.is-flex(v-for="social in socials", :key="social.id" :class="{'footer-socials__extend-page': checkRoute}")
      v-link(:aria-label="social.ariaLabel", :to='social.link', target='_blank', type="native", :noFollow="true")
        i.fab(:class="`fa-${social.name}`")
</template>

<script>
import { socialsMap } from '~~/utils/definitions/defaults'

export default {
  name: 'FooterSocialsComponent',

  computed: {
    socials() {
      return [
        {
          id: '50091167-261d-450e-8c93-ed5ce7430afa',
          name: 'pinterest-p',
          link: socialsMap[this.$site.name].pinterest,
          ariaLabel: 'Pinterest'
        },
        {
          id: 'f92d70bc-e7e7-427d-8525-9ec0c7edc998',
          name: 'facebook-f',
          link: socialsMap[this.$site.name].facebook,
          ariaLabel: 'Facebook'
        },
        {
          id: '9c8fa586-51ea-4683-b242-2b6e9347741a',
          name: 'instagram',
          link: socialsMap[this.$site.name].instagram,
          ariaLabel: 'Instagram'
        }
      ]
    },

    checkRoute() {
      const pages = ['extend-protection-plan']
      return !pages.includes(this.$route.name)
    }
  }
}
</script>

<style lang="sass" scoped>
.footer-socials
  align-items: center
  justify-content: flex-start
  &__title
    font-size: 16px
    text-transform: uppercase
    font-weight: bold
    margin-right: 28px
  .social
    justify-content: center
    align-items: center
    height: 35px
    width: 35px
    color: #fff
    border-radius: 50%
    background-color: #2e2e2e
    margin-right: 10px
    cursor: pointer
    a
      color: #fff
      i
        font-size: 20px
        display: block
  &__extend-page
    background-color: $dark-primary-color
</style>
